import { useEffect, useRef } from "react";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";

export const AudioTrack = ({ track, isLocal }) => {
  const { activeAudioSinkId } = useVideoContext();
  const audioEl = useRef();
  const id = useRef();

  useEffect(() => {
    if (isLocal) return;
    audioEl.current = track.attach();
    id.current = `at-${Math.random().toString(36).slice(-6)}`;
    audioEl.current.id = id.current;
    document.body.appendChild(audioEl.current);
    return () => {
      const audEl = track.detach(audioEl.current);
      audEl.remove();
    };
  }, [track]);

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeAudioSinkId);
  }, [activeAudioSinkId]);

  return null;
};
