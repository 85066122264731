import { useCallback, useRef, useState } from "react";
import { faCamera, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { ActionButton } from "./ActionButton";
import useDevices from "hooks/livekit/useDevices/useDevices";
import useLocalVideoToggle from "hooks/livekit/useLocalVideoToggle/useLocalVideoToggle";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";

export const ToggleLocalCamButton = ({ style = {} }) => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const [consensusTooltip, setConsensusTooltip] = useState("");
  const { activity } = useSessionActivity();
  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      if (activity?.id !== "multiple-choice") {
        toggleVideoEnabled();
      } else {
        setConsensusTooltip("Video is not available in Let's Talk game!");
      }
    }
  }, [activity, toggleVideoEnabled, setConsensusTooltip]);

  return (
    <ActionButton
      onClick={toggleVideo}
      style={style}
      // Video is muted and stopped being sent to the server once Multiple Choice activity starts
      // but isVideoEnabled does not propagate
      icon={activity?.id === "multiple-choice" ? faVideoSlash : isVideoEnabled ? faCamera : faVideoSlash}
      active={activity?.id === "multiple-choice" ? false : isVideoEnabled}
      tooltip={
        !hasVideoInputDevices
          ? "No Video Device Found"
          : consensusTooltip
          ? consensusTooltip
          : isVideoEnabled
          ? "Disable Camera"
          : "Enable Camera"
      }
    />
  );
};
