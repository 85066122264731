import { useCallback, useState } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";

export default function useLocalAudioToggle() {
  const [isMuted, setIsMuted] = useState(false);
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find((track) => track.kind === "audio");

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (audioTrack.isMuted) {
        audioTrack.unmute();
        setIsMuted(false);
      } else {
        audioTrack.mute();
        setIsMuted(true);
      }
    }
  }, [audioTrack]);
  return { isMuted, toggleAudioEnabled };
}
