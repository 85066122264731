import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import BootstrapProvider from "@bootstrap-styled/provider";
import { GlobalStyle } from "./GlobalStyle";
import { Routes } from "./Routes";
import { routes } from "config/routes";
import { theme } from "config/theme";
import { AppStateProvider } from "hooks/useAppState/useAppState";
import {
  AuthUserDataProvider,
  useAuthUserDataContext,
} from "hooks/useAuthUserData/useAuthUserData";

const WaitForUser = ({ children }) => {
  const { loading, error } = useAuthUserDataContext();

  if (error) {
    console.error("error", error);
    return (
      <div>Something went wrong loading the user. Please refresh the page.</div>
    );
  }

  if (loading) {
    return <div style={{ width: 10, height: 10, background: "#eee" }}></div>;
  }

  return <>{children}</>;
};

export const App = () => {
  return (
    <BootstrapProvider theme={theme} reset={true} injectGlobal={true}>
      <AuthUserDataProvider>
        <WaitForUser>
          <GlobalStyle />
          <React.StrictMode>
            <AppStateProvider>
              <Routes routes={routes} />
            </AppStateProvider>
          </React.StrictMode>
        </WaitForUser>
      </AuthUserDataProvider>
    </BootstrapProvider>
  );
};
