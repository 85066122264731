import { useContext, createContext } from "react";
import { useModal } from "./useModal";

export const useAppState = () => {
  const modal = useModal();

  return { modal };
};

const Context = createContext();

export const AppStateProvider = ({ children }) => {
  const value = useAppState();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAppStateContext = () => {
  return useContext(Context);
};
