import "./dashboard.css";
import { GroupCodeCard } from "./GroupCodeCard";
import React, { useEffect, useState } from "react";
import { request } from "../../../../hooks/useOkoApi/useOkoApi";
import * as Sentry from "@sentry/react";
import { useStandards } from "../../../../hooks/useStandards";
import { Alert } from "react-bootstrap";
import { colors, constants } from "utils";
import IconInfo from "../../../../assets/Icon-Info.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const GroupCodeView = () => {
  // showMessage prop controls Alert visibility
  // Can't use regular dismissable Alert here as there is a custom button in the design
  const [showMessage, setShowMessage] = useState(true);
  const standardsData = useStandards();
  const [educatorAccountData, setEducatorAccountData] = useState();
  const [codesToConfig, setCodesToConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getEducatorAccountData = async () => {
    try {
      setIsLoading(true);
      const educatorData = await request("account/educator", {
        method: "GET",
      });
      setEducatorAccountData(educatorData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
      setError(e?.detail || e?.message || "An unknown error occurred.");
    }
  };

  useEffect(() => {
    getEducatorAccountData();
  }, []);

  useEffect(() => {
    if (educatorAccountData && educatorAccountData.codes && educatorAccountData.codes.length > 0) {
      const codesMap = {};
      async function fetchGrades() {
        setIsLoading(true);
        for (const code in educatorAccountData.codes) {
          try {
            const data = await request(`group/${educatorAccountData.codes[code]}`, {
              method: "GET",
            });
            codesMap[educatorAccountData.codes[code]] =
              data.config;
            setIsLoading(false);
          } catch (e) {
            setIsLoading(false);
            Sentry.captureException(e);
            setError(e?.detail || e?.message || "An unknown error occurred.");
          }
        }
      }
      fetchGrades().then(r => setCodesToConfig(codesMap));
    }
  }, [educatorAccountData]);

  const triggerRefresh = async (code) => {
    setIsLoading(true);
    const newCodesToConfig = JSON.parse(JSON.stringify(codesToConfig));
    try {
      const data = await request(`group/${code}`, {
        method: "GET",
      });
      newCodesToConfig[code] = data.config;
      setCodesToConfig(newCodesToConfig);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
      setError(e?.detail || e?.message || "An unknown error occurred.");
    }
  }

  return (
    <div style={{
      display: "flex",
      backgroundColor: colors.milkGlass200,
      flexDirection: "column",
      gap: "32px",
      padding: "0px 32px"}}>
      <div style={{
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0px 1px 2px 0px rgba(14, 26, 49, 0.05)" }}>
        <div>
          {showMessage && <Alert style={{ marginBottom: 0 }}
                 variant="">
            <div style={{ display: "flex", gap: "16px" }}>
              <div style={{
                alignItems: "center",
                display: "flex",
                minHeight: "36px",
                gap: "12px",
                flex: "1 0 0",
                paddingRight: "28px" }}>
                <img src={IconInfo} />
                {constants.GROUP_CARD_VIEW_MESSAGE}
                <button className="group-code-view-message-alert-close-button" onClick={() => setShowMessage(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M19.847 18.2193C19.9166 18.289 19.9719 18.3717 20.0096 18.4628C20.0473 18.5538 20.0668 18.6514 20.0668 18.7499C20.0668 18.8485 20.0473 18.9461 20.0096 19.0371C19.9719 19.1281 19.9166 19.2109 19.847 19.2806C19.7773 19.3502 19.6946 19.4055 19.6035 19.4432C19.5125 19.4809 19.4149 19.5003 19.3163 19.5003C19.2178 19.5003 19.1202 19.4809 19.0292 19.4432C18.9381 19.4055 18.8554 19.3502 18.7857 19.2806L12.5663 13.0602L6.34696 19.2806C6.20623 19.4213 6.01536 19.5003 5.81633 19.5003C5.61731 19.5003 5.42644 19.4213 5.28571 19.2806C5.14498 19.1398 5.06592 18.949 5.06592 18.7499C5.06592 18.5509 5.14498 18.36 5.28571 18.2193L11.506 11.9999L5.28571 5.78055C5.14498 5.63982 5.06592 5.44895 5.06592 5.24993C5.06592 5.05091 5.14498 4.86003 5.28571 4.7193C5.42644 4.57857 5.61731 4.49951 5.81633 4.49951C6.01536 4.49951 6.20623 4.57857 6.34696 4.7193L12.5663 10.9396L18.7857 4.7193C18.9264 4.57857 19.1173 4.49951 19.3163 4.49951C19.5154 4.49951 19.7062 4.57857 19.847 4.7193C19.9877 4.86003 20.0668 5.05091 20.0668 5.24993C20.0668 5.44895 19.9877 5.63982 19.847 5.78055L13.6266 11.9999L19.847 18.2193Z"
                          fill={colors.nightlyWoods500}/>
                  </svg>
                </button>
              </div>
            </div>
          </Alert>}
        </div>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap:"32px" }}>
        {Object.entries(codesToConfig).map(([code,config]) => (
          <GroupCodeCard
            activeSessionQty="0" //TODO: Add BE API to get active sessions by group code
            activity={config.activity_type}
            code={code}
            key={code}
            standardPrefix={config.standard_prefix}
            standards={standardsData}
            triggerRefresh={triggerRefresh}
          >
          </GroupCodeCard>
        ))}
      </div>
      {isLoading && <div className="center-display shift-calc-right">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      {error &&
        <Alert style={{ overflowWrap: "break-word" }}
               className="center-display"
               dismissible
               onClose={() => setError(null)}
               variant="danger">{error}
        </Alert>
      }
    </div>
  );
};
