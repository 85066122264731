export const texts = {
  createSessionTitle: `Welcome to Oko`,
  createSessionDescription: `Enter a your access code below to create a new session.`,
  createSessionButtonText: `Create Session`,
  joinRoomTitle: "Welcome to Oko!",
  joinRoomDescription: `Enter your group code`,
  joinSessionTitle: `Ready to join?`,
  joinSessionDescription: `Choose a method below to sign in.`,
  menuExplination: `Want to start a new activity? Click here to get started.`,
  waitingRoomExplanationTitle: `You are in the waiting room...`,
  waitingRoomExplanationDescriptiopn: `Participants in the room see the box below. Use the link to share this session.`,
  waitingRoomDefaultTitle: `Waiting for session to start...`,
  waitingRoomDefaultSubtitle: `Once everything is ready the session will begin.`,
};
