import { useSessionContext } from "hooks/useSession";
import {
  FormGroup,
  Input,
  Form,
  H2,
  Card,
  CardBlock,
  Button,
} from "@bootstrap-styled/v4/";
import { DebugPanel } from "../../DebugPanel/DebugPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/free-solid-svg-icons";

export const HomePanel = ({ changeToPanel }) => {
  const { url } = useSessionContext();

  return (
    <>
      <Card className="mb-3">
        <CardBlock>
          <div className="d-flex justify-content-between">
            <H2>Welcome to Oko</H2>
            <Button
              onClick={() =>
                changeToPanel({ label: "Debug", component: DebugPanel })
              }
            >
              <FontAwesomeIcon icon={faBug} className="mr-1" /> Debug
            </Button>
          </div>

          <p className="mb-3">Use the url below to share this session:</p>
          <Form>
            <FormGroup className="mb-0">
              <Input
                type="url"
                name="url"
                id="exampleUrl"
                value={url}
                readOnly
              />
            </FormGroup>
          </Form>
        </CardBlock>
      </Card>
    </>
  );
};
