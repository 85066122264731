import { useAuthUserData } from "hooks/useAuthUserData/useAuthUserData";
import { Login } from "./Login";
import { Session } from "../Session/Session";

export const Home = () => {
  const { authUserData, loading } = useAuthUserData();
  if (loading) return <></>;
  if (authUserData.isAnonymous) return <Login />;
  return <Session />;
};
