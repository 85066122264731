import {
  Container,
  Row,
  Col,
  Section,
  Footer,
  Card,
} from "@bootstrap-styled/v4";

export const AdminCard = ({ children }) => {
  return (
    <div className="d-flex fullscreen flex-column justify-content-center slash-background">
      <Section>
        <Container>
          <Row>
            <Col className="d-flex flex-column justify-content-center mb-5">
              <Card
                className="p-5 m-auto d-flex flex-column  justify-content-center "
                style={{ maxWidth: 800 }}
              >
                {children}
              </Card>
            </Col>
          </Row>
        </Container>
      </Section>

      <Footer
        className="text-center p-3 text-small text-muted position-fixed w-100"
        style={{ bottom: 0, opacity: 0.4 }}
      >
        © {new Date().getFullYear()} Oko Labs, Inc. All right reserved.
      </Footer>
    </div>
  );
};
