import { H1, H4 } from "@bootstrap-styled/v4";
import { AdminCard } from "components/shared/AdminCard/AdminCard";
import logo from "assets/logo.png";
import { SignInButton } from "components/shared/SignInButton/SignInButton";

export const Login = () => {
  const onSuccess = async () => {};

  return (
    <AdminCard>
      <img src={logo} alt="logo" className="mb-4" style={{ width: 200 }} />

      <H1 className="mb-2">Login</H1>
      <H4 className="mb-4">
        Click the button below to login to your Oko Account.
      </H4>

      <SignInButton onSuccess={onSuccess} />
    </AdminCard>
  );
};
