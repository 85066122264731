import { useState } from "react";
import { H1, H4 } from "@bootstrap-styled/v4";
import { AdminCard } from "components/shared/AdminCard/AdminCard";
import logo from "assets/logo.png";
import { SettingsModal } from "./SettingsModal";
import { SignInButton } from "components/shared/SignInButton/SignInButton";

export const SignUp = () => {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const onSuccess = async (type) => {
    setIsSettingsModalOpen(true);
  };

  return (
    <>
      {isSettingsModalOpen && <SettingsModal isOpen isOnboarding setIsOpen={setIsSettingsModalOpen} />}
      <AdminCard>
        <img src={logo} alt="logo" className="mb-4" style={{ width: 200 }} />

        <H1 className="mb-2">Educator Sign Up</H1>
        <H4 className="mb-4">
          We're excited for your students to start using Oko!
        </H4>

        <SignInButton onSuccess={onSuccess} />
      </AdminCard>
    </>
  );
};
