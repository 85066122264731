import { colors } from "utils";

export const Header = ({ title }) => {
  return (
    <div className="shift-header-right" style={{
      backgroundColor: colors.nightlyWoods500,
      color: colors.milkGlass500,
      fontSize: "40px",
      fontWeight: 700,
      lineHeight: "44px",
      padding: "24px 32px" }}>
      {title}
    </div>
  );
};
