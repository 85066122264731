import styled from "styled-components";
import { useEffect, useState } from "react";
import { ParticipantEvent, Track } from "livekit-client";
import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay } from "./Overlay/Overlay";
import ParticipantTracks from "../ParticipantTracks/ParticipantTracks";

export const Participant = ({
  participant,
  videoOnly,
  enableScreenShare,
  isLocalParticipant,
  style,
}) => {
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  useEffect(() => {
    if (!participant) return;

    const trackMuted = (track) => {
      if (track.kind === Track.Kind.Audio) {
        setIsAudioMuted(true);
      } else if (track.kind === Track.Kind.Video) {
        setIsVideoMuted(true);
      }
    };

    const trackUnmuted = (track) => {
      if (track.kind === Track.Kind.Audio) {
        setIsAudioMuted(false);
      } else if (track.kind === Track.Kind.Video) {
        setIsVideoMuted(false);
      }
    };

    participant.on(ParticipantEvent.TrackMuted, trackMuted);
    participant.on(ParticipantEvent.TrackUnmuted, trackUnmuted);

    return () => {
      participant.off(ParticipantEvent.TrackMuted, trackMuted);
      participant.off(ParticipantEvent.TrackUnmuted, trackUnmuted);
    };
  }, [participant]);

  return (
    <Container style={style}>
      <Content>
        <AwayIcon icon={faUserAstronaut} />
        <ParticipantTracks
          participant={participant}
          videoOnly={videoOnly}
          enableScreenShare={enableScreenShare}
          isLocalParticipant={isLocalParticipant}
          isVideoMuted={isVideoMuted}
        />
        <Overlay participant={participant} isAudioMuted={isAudioMuted} isVideoMuted={isVideoMuted} />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-bottom: ${(9 / 16) * 100}%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  background: linear-gradient(to top, #38425a, #293042);

  & video {
    filter: none;
    object-fit: contain !important;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const AwayIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 50%;
  bottom: 50%;
  color: white;
  width: 50% !important;
  height: 50%;
  transform: translate(-50%, 50%);
`;
