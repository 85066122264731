import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Blob } from "./Blob";
import barsGifSrc from "assets/bars3.gif";
import noSoundPngSrc from "assets/no-sound.png";
import { useDataTrack } from "hooks/livekit/useDataTrack/useDataTrack";

export const OkoAssistant = () => {
  const [phrase, setPhrase] = useState("");

  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [showText, setShowText] = useState(false);

  useDataTrack((e) => {
    if (e.event_type === "speech_start") {
      setIsPlayingAudio(true);
      setShowText(true);
      setPhrase(stripTags(e.data.message));
    }
    if (e.event_type === "speech_end") {
      setIsPlayingAudio(false);
      setShowText(phrase);

      setTimeout(() => {
        if (showText === phrase) setShowText(false);
      }, 8000);
    }
  });

  return (
    <AnimatePresence>
      {phrase && showText && (
        <Wrapper
          exitBeforeEnter
          key={phrase}
          initial={{ y: -300 }}
          animate={{ y: 0 }}
          exit={{ y: -300 }}
        >
          <Icon>
            <Blob />
            <div
              style={{
                backgroundImage: `url(${
                  isPlayingAudio ? barsGifSrc : noSoundPngSrc
                })`,
                width: 40,
                height: 40,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: 100,
                position: "absolute",
                backgroundSize: "cover",
              }}
            ></div>
          </Icon>
          <AnimatePresence>
            <TextContainer
              transition={{
                delay: 0.7,
              }}
              initial={{ width: 0, whiteSpace: "no-wrap" }}
              animate={{ width: "80vw", whiteSpace: "normal" }}
            >
              <div style={{ fontSize: 18 }}>{trimPhrase(phrase)}</div>
            </TextContainer>
          </AnimatePresence>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

function stripTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, "");
}

// The following function is needed as "text-overflow: ellipsis" works only with "white-space: no-wrap" in CSS.
// Adding the latter results in Oko Assistant phrase being limited to 1 line.
function trimPhrase(str) {
  const maxLength = 230;
  return str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str.substring(0, maxLength);
}

const Wrapper = styled(motion.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  left: 10vw;
  top: 0;
  padding: 4px;
`;

const Icon = styled.div`
  width: 60px;
  height: 60px;
  margin-right: -34px;
  position: relative;
`;

const TextContainer = styled(motion.div)`
  align-self: normal;
  line-height: 1;
  overflow: hidden;
  font-size: 30px;
  height: 60px;
  border-radius: 0.25rem;

  > div {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 0 1px #ebe9f1;
    padding-right: 24px;
    padding-left: 48px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #583dcd;
  }
`;
