import "./multipleChoiceActivity.css"
import React from "react";
import styled from "styled-components";
import { Alert, Card, ListGroup } from "@bootstrap-styled/v4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Timer } from "./CountdownTimer";
import { VerticalFraction } from "./VerticalFraction";
import { MathJax } from "./MathJax";

export const MultipleChoiceActivityRender = ({
                                             hideQuestion,
                                             timerTimeRemaining,
                                             selectedIndex,
                                             challenge,
                                             disableOptions,
                                             onAnswer,
                                             isMathJaxSet}) => {
  const renderPromptImage = (url) => {
    if (!url || url === 'nan') {
      return null;
    } else {
      return <img src={url} style={{
        alignSelf: "center",
        maxHeight: "fit-content",
        maxWidth: "-webkit-fill-available",
        padding: '20px 0' }} alt="Prompt image"/>
    }
  };

  const renderedPrompt = challenge.render_type === 'math' ?
    // Can not use handleMultiLine function here as it could match MathJax's \ne in addition to \n
    <MathJax expression={challenge.prompt} isMathJaxSet={isMathJaxSet} /> :
    <>
      {handleMultiLine(challenge.prompt)}
    </>;

  return (
    <>
      {!hideQuestion && (
        <Card className="p-5" style={{ borderRadius: "1rem", maxWidth: 800, position: "relative" }}>
          {timerTimeRemaining && timerTimeRemaining > 1 && (
            <Card
              className="p-3 mb-2 "
              style={{
                display: "flex",
                position: "absolute",
                left: 0,
                top: 0,
                transform: "translate(-30px, -50%)",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.2rem",
                borderRadius: 12,
              }}
            >
              <Timer timeRemaining={timerTimeRemaining}/>
              <span className="mr-2 ml-2">
                {timerTimeRemaining} seconds remain!
              </span>
            </Card>
          )}

          {selectedIndex !== null && (
            <Alert
              style={{
                display: "flex",
                position: "absolute",
                left: 0,
                top: 0,
                transform: "translate(-30px, -50%)",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.2rem",
                borderRadius: 12,
              }}
            >
              Thanks for picking an answer!
            </Alert>
          )}
          <span className="prompt">
            {renderedPrompt}
          </span>
          {renderPromptImage(challenge.internal_image_url ?? challenge.prompt_image_url)}
          <ListGroup style={{ border: "none", translate: "-21px" }}>
            {challenge.answer_options.map((option, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                  color={"#5CAF64"}
                  size="3x"
                  icon={faCheckCircle}
                  style={{ marginBottom: "1rem", marginRight: "1rem",
                    opacity: challenge.solution_index === index ? 1 : 0 }}
                />
                <Option
                  className={`w-100 d-flex ${
                    !option.eligible || selectedIndex || disableOptions
                      ? `disabled`
                      : ""
                  }`}
                  key={index + option.id}
                  style={{ marginBottom: "1rem", padding: "1rem", opacity: option.eligible ? 1 : 0.3 }}
                  onClick={() => {
                    onAnswer(option, index);
                  }}
                >
                  <div>
                    <span key={index + option.id + "_number"} style={{ fontSize: 14, marginRight: "0.5rem" }}>
                      {numberToLetters(index)} )
                    </span>
                    <span className="answer" key={index + option.id + "_option"}>
                      {challenge.render_type === 'math' ?
                        (
                          <MathJax expression={option.text} isMathJaxSet={isMathJaxSet} />
                        ) : (
                          <>
                            {handleVerticalFractions(option.text)}
                          </>
                        )}
                    </span>
                  </div>
                </Option>
              </div>
            ))}
          </ListGroup>
        </Card>
      )}
    </>
  );
};

const Option = styled(Card)`
  border-radius: 1rem !important;
  cursor: pointer;
  font-size: 1.3rem;
  flex-direction: row !important;
  align-items: center !important;
  background: #84ff90 !important;

  &:hover {
    background-color: #c7ffcd !important;
  }

  &.disabled {
    cursor: not-allowed;
    background: #f8f9fa !important;
  }

  &.selected {
    background: #84e2ff !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
`;

//convert number to abc etc
const numberToLetters = (number) => {
  var abc = "abcdefghijklmnopqrstuvwxyz";
  var abcLength = abc.length;
  var converted = "";

  while (number >= 0) {
    converted = abc.charAt(number % abcLength) + converted;
    number = Math.floor(number / abcLength) - 1;
  }

  return converted;
};

const handleMultiLine = (text) => {
  const newLine = '\\n'
  if(!text.includes(newLine)) return handleVerticalFractions(text);

  return text.split(newLine).map(
    (line, index) => (
      <React.Fragment key={index}>
        <div>{handleVerticalFractions(line)}</div>
        <br />
      </React.Fragment>
    ))
};

const handleVerticalFractions = (text) => {
  text = text.replace(/\u00a0/g, " "); // this replaces all NBSP characters with space
  if (text.indexOf("/") < 1) return text; // also handling a case where "/" is at index 0 - not a valid fraction
  const words = text.split(" ");
  let insertSpaceAfterFraction = false;
  for (let i = 0; i < words.length; i++) {
    if (words[i].indexOf("/") > 0) { // word with "/" at index 0 is not a valid fraction
      let top = words[i].split("/")[0];
      let charsBeforeFraction = "";
      // Handling a case where there is non-number char in the top, like opening parenthesis
      for (let j = 0; j < top.length; j++) {
        if (!isNumber(top.charAt(j))) {
          charsBeforeFraction = charsBeforeFraction + top.charAt(j);
        } else {
          break;
        }
      }
      top = top.slice(charsBeforeFraction.length);
      let bottom = words[i].split("/")[1];
      let charsAfterFraction = "";
      // Handling a case where there is no space after the fraction and non-number char goes next like "1/8?"
      for (let j = 0; j < bottom.length; j++) {
        if (!isNumber(bottom.charAt(j))) {
          charsAfterFraction = bottom.substring(j, bottom.length);
          bottom = bottom.substring(0, j);
          break;
        }
      }
      words[i] = <span>
        {charsBeforeFraction && <span>{charsBeforeFraction}</span>}
        <VerticalFraction key={i} numerator={top} denominator={bottom}/>
        {charsAfterFraction && <span>{charsAfterFraction}</span>}
      </span>;
      insertSpaceAfterFraction = true;
    } else {
      if (insertSpaceAfterFraction) {
        words[i] = <span key={i}>{" " + words[i] + " "}</span>;
      } else {
        words[i] = <span key={i}>{words[i] + " "}</span>;
      }
      insertSpaceAfterFraction = false;
    }
  }
  return words;
};

const isNumber = (str) => {
  if (typeof str != "string") return false
  return !isNaN(str) && !isNaN(parseFloat(str));
};
