import {
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from "config/livekit";
import { useCallback, useState } from "react";
import { createLocalTracks, VideoPresets } from "livekit-client";
import useDevices from "../../useDevices/useDevices";
export default function useLocalTracks() {
  const videoResolution = VideoPresets.h360.resolution; // For testing, so we don't use more bandwidth
  const [audioTrack, setAudioTrack] = useState();
  const [videoTrack, setVideoTrack] = useState();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);
  const {
    audioInputDevices,
    videoInputDevices,
    hasAudioInputDevices,
    hasVideoInputDevices,
  } = useDevices();

  const getAudioAndVideoTracks = useCallback(() => {
    if (!hasAudioInputDevices && !hasVideoInputDevices)
      return Promise.resolve();
    if (isAcquiringLocalTracks || audioTrack || videoTrack)
      return Promise.resolve();
    setIsAcquiringLocalTracks(true);
    const selectedAudioDeviceId = window.localStorage.getItem(
      SELECTED_AUDIO_INPUT_KEY
    );
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    );
    const hasSelectedAudioDevice = audioInputDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    );
    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    );
    const localTrackConstraints = {
      video: hasVideoInputDevices && {
        resolution: videoResolution,
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: { exact: selectedVideoDeviceId },
        }),
      },
      audio: hasAudioInputDevices && {
        name: `audio-${Date.now()}`,
        ...(hasSelectedAudioDevice && {
          deviceId: { exact: selectedAudioDeviceId },
        }),
      },
    };

    return createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        const videoTrack = tracks.find((track) => track.kind === "video");
        const audioTrack = tracks.find((track) => track.kind === "audio");
        if (videoTrack) {
          setVideoTrack(videoTrack);
        }
        if (audioTrack) {
          // noise cancellation is enabled by default in LiveKit
          setAudioTrack(audioTrack);
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [
    hasAudioInputDevices,
    hasVideoInputDevices,
    audioTrack,
    videoTrack,
    audioInputDevices,
    videoInputDevices,
    isAcquiringLocalTracks,
  ]);

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  );

  return {
    localTracks,
    isAcquiringLocalTracks,
    getAudioAndVideoTracks,
  };
}
