import { useState } from "react";
import { Toggle } from "components/shared/Toggle/Toggle";
import { useOkoSessionContext } from "hooks/useOkoSession/useOkoSession";
import { useSessionContext } from "hooks/useSession";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { useActivity } from "hooks/useActivity/useActivity";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
} from "@bootstrap-styled/v4";

export const OkoLessonPanel = () => {
  const { update: updateSession, session } = useSessionContext();
  const { start, terminate } = useOkoSessionContext();
  const { change: changeActivity } = useSessionActivity();
  const { update: updateActivity } = useActivity();
  const [hideOtherMembersVideo, setHideOtherMembersVideo] = useState(false);
  const [formData, setFormData] = useState({
    initial_difficulty_level: 1,
    timeout_in_minutes: 20,
  });

  const onFormChange = (e) => {
    let { name, value } = e.target;

    if (e.target.getAttribute("type") === "checkbox") {
      value = !!e.target.checked;
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (hideOtherMembersVideo) {
      updateSession({ hideOtherMembersVideo: true });
    }
    changeActivity({ id: "waiting-room" });
    updateActivity(
      {
        title: "Please Wait",
        subtitle: "Oko is preparing your activity...",
      },
      "waiting-room"
    );
    start(session, formData);
  };

  return (
    <div>
      <Card className="p-2">
        <Form onSubmit={onFormSubmit}>
          <FormGroup className="form-group">
            <Label htmlFor="initial_difficulty_level">
              Initial Difficulty Level
            </Label>
            <Input
              type="number"
              value={formData.initial_difficulty_level}
              min={1}
              max={5}
              name="initial_difficulty_level"
              onChange={onFormChange}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <Label htmlFor="timeout_in_minutes">Timeout (minutes)</Label>
            <Input
              type="number"
              value={formData.timeout_in_minutes}
              min={1}
              max={100}
              name="timeout_in_minutes"
              onChange={onFormChange}
            />
          </FormGroup>
          <div className="mt-2 mb-2">
            <Toggle
              checked={hideOtherMembersVideo}
              onChange={() => {
                setHideOtherMembersVideo(!hideOtherMembersVideo);
              }}
            />
            <span style={{ position: "relative", left: 8, top: -8 }}>
              Students only see their video, other students videos are hidden
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <Button className="mr-2" type="submit">
              Start Oko Session
            </Button>{" "}
            <Button className="mr-2 " onClick={terminate} color="danger">
              Terminate Oko Session
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};
