import { useState } from "react";
import { H1, Alert, Button, Form, Label, Input } from "@bootstrap-styled/v4";
import { texts } from "config/texts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { request } from "hooks/useOkoApi/useOkoApi";
import { getRef } from "hooks/useSession";
import { useFirebase } from "hooks/useFirebase";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useUser } from "hooks/useUser/useUser";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { useSessionContext } from "hooks/useSession";
import * as Sentry from "@sentry/react";

export const GroupCode = () => {
  const { setSessionId } = useSessionContext();
  const [error, setError] = useState();
  const [code, setCode] = useState(
    new URLSearchParams(window.location.search).get("code")
  );
  const firebase = useFirebase();
  const { connect: connectVideo } = useVideoContext();
  const { authUserData } = useAuthUserDataContext();
  const { update: updateUser } = useUser(authUserData?.id);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const groupResponse = await request(`group/${code}/join`, { method: "GET" });
      setSessionId(groupResponse.session_id);
      connectVideo(groupResponse.livekit_url, groupResponse.livekit_auth_token);
      const ref = getRef(groupResponse.session_id);
      const sessionResponse = await request(`session/?session_id=${groupResponse.session_id}`, { method: "GET" });
      const sessionData = (await ref.get()).data();
      if (!sessionData) {
        await ref.set(
          {
            id: groupResponse.session_id,
            sessionId: groupResponse.session_id,
            okoSessionId: groupResponse.session_id,
            creatorId: "server",
            auth: groupResponse,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            autoStart: true,
            activityId: sessionResponse.activity_type ? "ready-room" : "icon-select",
            sessionActivityType: sessionResponse.activity_type ? sessionResponse.activity_type : null,
            code: "NOLONGERUSED",
          },
          { merge: true }
        );
      }

      const displayName = authUserData.displayName;
      let role = "student";

      await updateUser({ displayName, role });

    } catch (e) {
        Sentry.captureException(e);
        setError(e);
        console.log(e);
    }
  };

  return (
    <>
      <H1>{texts.joinRoomTitle}</H1>
      <Form onSubmit={onSubmit}>
        <Label className="mb-2 text-secondary">
          {texts.joinRoomDescription}:
        </Label>
        <Input
          placeholder={texts.joinRoomDescription}
          size="lg"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button color="primary" type="submit" size="lg" className=" w-100 mt-2">
          Join <FontAwesomeIcon icon={faSignInAlt} className="ml-2" />
        </Button>
      </Form>
      {error && (
        <Alert color="danger">
          {error?.detail || "An unknown error occurred."}
        </Alert>
      )}
    </>
  );
};
