import { useState, useEffect } from "react";
import styled from "styled-components";
import { Video } from "../shared/Video/Video";
import { VideoActionBar } from "../shared/VideoActionBar/VideoActionBar";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useSessionContext } from "hooks/useSession";
import { Card, Container, Row, Col, Section, Footer } from "@bootstrap-styled/v4";
import { GroupCode } from "../GroupCode";
import { Track } from "livekit-client";

export const Join = () => {
  const { authUserData } = useAuthUserDataContext();
  const { session } = useSessionContext();
  const [sessionReady, setSessionReady] = useState(false);
  const { localTracks } = useVideoContext();
  const videoTrack = localTracks.find((track) => track?.source?.includes(Track.Source.Camera));

  useEffect(() => {
    if (!session) return;
    if (sessionReady) return;
    if (session?.creatorId !== authUserData?.id) return;
    setSessionReady(true);
  }, [setSessionReady, sessionReady, authUserData, session]);

  // Hide join dialog if session is not ready, work on more robust
  return (
    <div className="d-flex fullscreen flex-column justify-content-center slash-background">
      <Section>
        <Container>
          <Row>
            <Col md="6" className="d-flex  justify-content-center">
              <Card className="p-lg-4 p-2 d-flex  justify-content-center w-100">
                <GroupCode></GroupCode>
              </Card>
            </Col>
            <Col className="d-flex justify-content-center align-items-center pb-3 pb-md-0">
              <VideoWrapper>
                <Video track={videoTrack} isLocal />
                <VideoControlBarWrapper>
                  <VideoActionBar fullHeight />
                </VideoControlBarWrapper>
              </VideoWrapper>
            </Col>
          </Row>
        </Container>
      </Section>

      <Footer
        className="text-center p-3 text-small text-muted position-fixed w-100"
        style={{ bottom: 0, opacity: 0.4 }}
      >
        © {new Date().getFullYear()} Oko Labs, Inc. All right reserved.
      </Footer>
    </div>
  );
};

const VideoWrapper = styled(Card)`
  position: relative;
  width: 100%;
`;

const VideoControlBarWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px;
  height: 100%;
`;
