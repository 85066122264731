import "./side.css";
import { Side } from "./Side";
import { colors } from "utils";
import { Header } from "./Header";

export const SidebarView = ({ viewComponent, headerTitle }) => {
  return (
    <div style={{ backgroundColor: colors.milkGlass200, display: "flex", flexDirection: "row"}}>
      <Side />
      <div className="shift-right">
        <Header title={headerTitle}/>
        {viewComponent}
      </div>
    </div>
  );
};
