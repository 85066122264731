import { useState } from "react";
import styled from "styled-components";
import { Fullscreen } from "components/shared/Fullscreen/Fullscreen";
import { Button } from "@bootstrap-styled/v4";
import { H2 } from "@bootstrap-styled/v4/";
import { useActivity } from "hooks/useActivity/useActivity";
import { useMembers } from "hooks/livekit/useMembers/useMembers";
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { useSessionContext } from "hooks/useSession";
import { request } from "hooks/useOkoApi/useOkoApi";
import { VideosBar } from "components/pages/Session/shared/VideosBar/VideosBar";

export const ReadyRoom = () => {
  const { session } = useSessionContext();
  const { change: changeActivity } = useSessionActivity();
  const { update: updateActivity } = useActivity();
  const { members } = useMembers({ includeObservers: false });
  const [hasVoted, setHasVoted] = useState(false);

  const onStart = async () => {
    const res = await request(`session/${session?.id}/vote_to_start`);
    setHasVoted(true);

    if (res?.votes_so_far === res?.total_votes_required) {
      changeActivity({ id: "waiting-room" });
      updateActivity(
        {
          title: "Please Wait",
          subtitle: "Oko is preparing your activity...",
          showParticipants: false,
          showParticipantVideos: false,
        },
        "waiting-room"
      );
    }
  };

  return (
    <StyledFullscreen>
      <>
        {members.length > 1 && !hasVoted && (
          <H2 className="mb-3">Is this everyone in your Oko Group?</H2>
        )}
        <div style={{ width: "96vw", marginTop: 10 }}>
          <VideosBar />
        </div>
        {members.length > 1 && !hasVoted && (
          <Button onClick={onStart} className="mt-3">
            Yes that's everyone - let's start
          </Button>
        )}
      </>
    </StyledFullscreen>
  );
};

const StyledFullscreen = styled(Fullscreen)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
