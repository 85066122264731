import useTrack from "hooks/livekit/useTrack/useTrack";
import { AudioTrack } from "../AudioTrack/AudioTrack";
import { VideoTrack } from "../VideoTrack/VideoTrack";
import { Track } from "livekit-client";

export default function Publication({
  publication,
  isLocalParticipant,
  videoOnly,
  videoPriority,
  isVideoMuted,
}) {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case Track.Kind.Video:
      return (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={isLocalParticipant && track?.source.includes(Track.Source.Camera)}
          disabled={isVideoMuted}
        />
      );
    case Track.Kind.Audio:
      return videoOnly ? null : (
        <AudioTrack track={track} isLocal={isLocalParticipant} />
      );
    default:
      return null;
  }
}
