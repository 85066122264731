import { useState } from "react";
import { motion } from "framer-motion";
import { Join } from "./Join/Join";
import { Activity } from "./Activity/Activity";
import { MenuButton } from "./MenuButton";
import { Dashboard } from "./Dashboard/Dashboard";
import { TTSParticipant } from "./TTSParticipant";
import { LiveKitProgrammableVideoProvider } from "hooks/livekit/useLiveKitProgramableVideo/useLiveKitProgramableVideo";
import useConnectionOptions from "hooks/livekit/useConnectionOptions/useConnectionOptions";
import useRoomOptions from "hooks/livekit/useRoomOptions/useRoomOptions";
import { SessionProvider } from "hooks/useSession";
import useRoomState from "hooks/livekit/useRoomState/useRoomState";
import { GestureStateProvider } from "hooks/useGestureState/useGestureState";
import { useAdminPermission } from "hooks/useAdminPermission/useAdminPermission";
import DeviceSettingsModal from "./DeviceSettingsModal/DeviceSettingsModal";
import { OkoSessionProvider } from "hooks/useOkoSession/useOkoSession";
import {
  faBars,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { RoomEvent } from "livekit-client";

export const Session = () => {
  const connectionOptions = useConnectionOptions();
  const roomOptions = useRoomOptions();

  const onError = (error) => {
    console.log("LiveKitProgrammableVideoProvider Error", error);
  };

  return (
    <SessionProvider>
      <LiveKitProgrammableVideoProvider
        connectionOptions={connectionOptions}
        roomOptions={roomOptions}
        onError={onError}
      >
        <OkoSessionProvider>
          <DeviceSettingsModal />
          <GestureStateProvider>
            <Room />
          </GestureStateProvider>
        </OkoSessionProvider>
      </LiveKitProgrammableVideoProvider>
    </SessionProvider>
  );
};

const Room = () => {
  const roomState = useRoomState();
  const { hasAdminPermission } = useAdminPermission();

  if (roomState === RoomEvent.Disconnected) return <Join />;

  return (
    <>
      <TTSParticipant />
      {hasAdminPermission ? <AdminUi /> : <Activity />}
    </>
  );
};

const AdminUi = () => {
  const [showDashboard, setShowDashboard] = useState(false);

  return (
    <>
      <MenuButton
        style={{ position: "absolute", left: 16, top: 16 }}
        icon={showDashboard ? faExternalLinkSquareAlt : faBars}
        onClick={() => {
          setShowDashboard(!showDashboard);
        }}
      />
      {showDashboard && <Dashboard />}
      <motion.div
        style={{
          transformOrigin: "top left",
          cursor: showDashboard ? "pointer" : "auto",
          boxShadow: "transparent 1px 1px 1px 1px",
        }}
        animate={{
          scale: showDashboard ? (260 - 20) / window.innerWidth : 1,
          x: showDashboard ? 10 : 0,
          y: showDashboard ? 10 : 0,
        }}
        transition={{ type: "linear" }}
        onClick={() => {
          showDashboard && setShowDashboard(!showDashboard);
        }}
      >
        <Activity disable={showDashboard} />
      </motion.div>
    </>
  );
};
