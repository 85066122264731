import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCat } from "@fortawesome/free-solid-svg-icons";

export const ActionButton = ({
  icon = faCat,
  active = true,
  tooltip = "Toggle button",
  className = "",
  onClick = () => {},
  style = {},
}) => {
  return (
    <Wrapper
      className={`${className}`}
      styles={active ? styles["active"] : styles["inactive"]}
      onClick={onClick}
      style={style}
    >
      <FontAwesomeIcon icon={icon} size="lg" />
      {tooltip && <Tooptip className="tooltip">{tooltip}</Tooptip>}
    </Wrapper>
  );
};

const borderSize = 4;

const styles = {
  inactive: { colors: ["#4c4949", "#7B7B7B", "#7B7B7B"] },
  active: { colors: ["#1462ff", "#5b86e5", "#1462ff"] },
};

const Wrapper = styled.div`
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: relative;
  padding: ${borderSize}px;
  border-radius: 1000pc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: solid ${borderSize}px transparent;

  background-image: linear-gradient(#fff, #fff),
    linear-gradient(
      to bottom,
      ${(props) => props.styles.colors[0]},
      ${(props) => props.styles.colors[1]}
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 0px 1000px 0px ${(props) => props.styles.colors[2]} inset;
  user-select: none;
  transition: background-image 0.2s ease-in-out;

  svg {
    filter: drop-shadow(0 1px 0 #073eab);
  }

  :hover {
    .tooltip {
      display: block;
      opacity: 1;
    }
  }
`;

const Tooptip = styled.div`
  background: black;
  position: absolute;
  display: none;
  color: white;
  left: 60px;
  padding: 6px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 16px;
`;

export * from "./ToggleLocalCamButton";
export * from "./ToggleLocalMicButton";
