import "../multipleChoiceActivity.css"
import React from 'react';
import { useState } from "react";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { request } from "../../../../../../../hooks/useOkoApi/useOkoApi";
import { useStandards } from "../../../../../../../hooks/useStandards";
import { MultipleChoiceActivityRender } from "../MultipleChoiceActivityRender";
import { StyledFullscreen } from "../MultipleChoiceActivity";
import { buildSelectOptions} from "utils/standards";
import { EditMultipleChoiceQuestionModal } from "./EditMultipleChoiceQuestionModal";
import { buildPreviewChallenge } from "utils/challenge";

export const MultipleChoiceQuestionRender = () => {
  const standardsData = useStandards();
  const [questionsData, setQuestionsData] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [challengeToEdit, setChallengeToEdit] = useState();
  const getQuestionsData = async (prefix) => {
    try {
      setIsLoading(true);
      const questionsData = await request(
        `questions/fetch/multiplechoice?${prefix ? `standard_prefix=${prefix}` : ''}`, {
        method: "GET",
      });
      setQuestionsData(questionsData);
      setPrefix(prefix ? `for prefix ${prefix}` : "");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  const getOptions = () => {
    const options = buildSelectOptions(standardsData);
    options.unshift({"value": null, label: "All available standards"});
    return options;
  };

  const handleOnChange = (target) => {
    getQuestionsData(target.value);
  };

  const onEdit = (challenge) => {
    setChallengeToEdit(challenge);
    setIsModalOpen(true);
  };

  const styles = {
    control: (css) => ({
      ...css,
      width: "800px",
    }),
  };

  return (
    <div>
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%"}}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "35px" }}>
        <p>Fetched {questionsData.length} challenges {prefix}</p>
        <br/>
        <Select isDisabled={isLoading} isSearchable placeholder="Select standard..." options={getOptions()} onChange={handleOnChange} styles={styles}/>
      </div>
      {!isLoading && <StyledFullscreen>
        {questionsData.map((challenge, index) => (
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <MultipleChoiceActivityRender
              key={index}
              hideQuestion={false}
              timerTimeRemaining={null}
              selectedIndex={null}
              challenge={buildPreviewChallenge(challenge)}
              disableOptions={null}
              onAnswer={() => {}}
              isMathJaxSet={false} // Needed to prevent race condition on MathJax typesetting.
            />
            <button style={{
              borderRadius: "100px",
              borderWidth: "1px",
              marginLeft: "18px",
              width: "75px",
              height: "75px",
              alignSelf: "center"
            }} onClick={() => onEdit(challenge)}>
              <FontAwesomeIcon icon={faEdit} />
              &nbsp;Edit
            </button>
            <br/>
          </div>
        ))}
      </StyledFullscreen>
      }
      {challengeToEdit && <EditMultipleChoiceQuestionModal challenge={challengeToEdit}
                                                           isModalOpen={isModalOpen}
                                                           modalTitle="Edit Challenge"
                                                           setIsModalOpen={setIsModalOpen}/>
      }
    </div>
  );
};
