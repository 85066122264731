import { useCallback, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { useSessionActivity } from "hooks/useSessionActivity/useSessionActivity";
import { Track } from "livekit-client";
export default function useLocalVideoToggle() {
    const { room: { localParticipant }, localTracks } = useVideoContext();
    const videoTrack = localTracks.find((track) => track?.source.includes(Track.Source.Camera));
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const { activityId } = useSessionActivity();
    const toggleVideoEnabled = useCallback(() => {
        if (videoTrack) {
            // Video is not allowed in Multiple Choice activity
            if (activityId === "multiple-choice" || !videoTrack.isMuted) {
                videoTrack.mute();
                setIsVideoMuted(true);
            } else if (videoTrack.isMuted){
                videoTrack.unmute();
                setIsVideoMuted(false);
            }
        }
    }, [videoTrack, localParticipant, activityId]);
    return [!isVideoMuted, toggleVideoEnabled];
}
