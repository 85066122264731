import "./multipleChoiceActivityQuestionView.css"
import React from 'react';
import { useState } from "react";
import * as Sentry from "@sentry/react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { request } from "../../../../../../../hooks/useOkoApi/useOkoApi";
import { useStandards } from "../../../../../../../hooks/useStandards";
import { buildSelectOptions} from "utils/standards";
import { MultipleChoiceQuestion } from "./MultipleChoiceQuestion";
import Accordion from "react-bootstrap/Accordion";
import { EditMultipleChoiceQuestionModal } from "./EditMultipleChoiceQuestionModal";
import { buildEmptyChallenge } from "utils/challenge";

export const MultipleChoiceQuestionView = () => {
  const standardsData = useStandards();
  const [questionsData, setQuestionsData] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getQuestionsData = async (prefix) => {
    try {
      setIsLoading(true);
      const questionsData = await request(
        `questions/fetch/multiplechoice?${prefix ? `standard_prefix=${prefix}` : ''}`, {
          method: "GET",
        });
      setQuestionsData(questionsData);
      setPrefix(prefix ? `for prefix ${prefix}` : "");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  const getOptions = () => {
    const options = buildSelectOptions(standardsData);
    options.unshift({"value": null, label: "All available standards"});
    return options;
  };

  const handleOnChange = (target) => {
    getQuestionsData(target.value);
  };

  const styles = {
    control: (css) => ({
      ...css,
      width: "800px",
    }),
  };

  const handleAddNewChallengeButton = () => {
    setIsModalOpen(true);
  };

  const newChallengeButton = () => {
    return <div className="new-challenge-button">
      <button style={{ borderRadius: "100px", borderWidth: "1px", marginLeft: "18px" }}
              onClick={handleAddNewChallengeButton}>
        <FontAwesomeIcon icon={faPlus}/>
        &nbsp;Add new challenge item
      </button>
    </div>;
  }

  return (
    <div>
      {isLoading && <div style={{ left: "50%", position: "absolute", top: "50%" }}>
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          size="5x"
        />
      </div>}
      <div style={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "15px" }}>
        <p>Fetched {questionsData.length} challenges {prefix}</p>
        <br/>
        <Select isDisabled={isLoading} isSearchable placeholder="Select standard..." options={getOptions()}
                onChange={handleOnChange} styles={styles}/>
      </div>
      {!isLoading && newChallengeButton()}
      {!isLoading && <Accordion style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px" }}>
        {questionsData.map((challenge, index) => (
          <MultipleChoiceQuestion challenge={challenge} index={index} showHeader />
        ))}
      </Accordion>
      }
      <EditMultipleChoiceQuestionModal challenge={buildEmptyChallenge()}
                                       isModalOpen={isModalOpen}
                                       modalTitle="Create New Challenge"
                                       setIsModalOpen={setIsModalOpen}/>
    </div>
  );
};
