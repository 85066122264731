import { Home } from "components/pages/Home/Home";
import { Session } from "components/pages/Session/Session";
import { NotFound } from "components/pages/NotFound/NotFound";
import { SignUp } from "components/pages/Educator/SignUp/SignUp";
import { Login } from "components/pages/Educator/Login/Login";
import {
  MultipleChoiceQuestionRender,
} from "components/pages/Session/Activity/activities/MultipleChoiceActivity/Edit/MultipleChoiceQuestionRender";
import {
  MultipleChoiceQuestionView
} from "components/pages/Session/Activity/activities/MultipleChoiceActivity/Edit/MultipleChoiceQuestionView";
import {
  CrazyCalculatorQuestionRender
} from "../components/pages/Session/Activity/activities/CalculatorActivity/Edit/CrazyCalculatorQuestionRender";
import {
  CrazyCalculatorQuestionView
} from "../components/pages/Session/Activity/activities/CalculatorActivity/Edit/CrazyCalculatorQuestionView";
import { GroupCodeView } from "../components/pages/Educator/Dashboard/GroupCodeView";
import { SidebarView } from "../components/SidebarView";
import { constants } from "utils";
import { SessionHistory } from "../components/pages/Educator/Session/SessionHistory";

export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    component: Home,
  },
  {
    name: "Session",
    path: "/session/:sessionId",
    component: Session,
  },
  {
    path: "/educator/signup",
    component: SignUp,
  },

  {
    path: "/educator/login",
    component: Login,
  },
  {
    path: "/educator",
    name: "EducatorProfile",
    component: () => <>
      <SidebarView viewComponent={
        <div style={{ marginTop: "32px" }}>
          <GroupCodeView />
        </div>} headerTitle={constants.DASHBOARD}>
      </SidebarView>
    </>,
  },
  {
    path: "/session_history",
    component: () => <>
      <SidebarView viewComponent={
        <div style={{ padding: "32px" }}>
          <SessionHistory/>
        </div>} headerTitle={constants.SESSION_HISTORY}>
      </SidebarView>
    </>,
  },
  {
    path: "/admin/consensus/questions/render",
    name: "AdminConsensusQuestionsRender",
    component: MultipleChoiceQuestionRender,
  },
  {
    path: "/admin/consensus/questions/view",
    name: "AdminConsensusQuestionsView",
    component: MultipleChoiceQuestionView,
  },
  {
    path: "/admin/crazycalculator/questions/render",
    name: "AdminCrazyCalculatorQuestionsRender",
    component: CrazyCalculatorQuestionRender,
  },
  {
    path: "/admin/crazycalculator/questions/view",
    name: "AdminCrazyCalculatorQuestionsView",
    component: CrazyCalculatorQuestionView,
  },
  {
    component: NotFound,
  },
];
